<template>
  <div class="page">
    <div class="fab" @click="showAdd = true">
      <van-icon name="plus" color="white" />
    </div>
    <van-empty description="还没有数据哦" v-if="!loading && finished && list.length === 0 && finishedList.length === 0" style="position: fixed;top: 30vh;left: 0;right: 0;"/>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" @load="onLoad">
        <van-collapse v-model="activeGroup" accordion @change="changeGroup">
          <van-collapse-item name="1">
            <template #title>
              <div>未完成<van-tag style="margin-left: 10px" plain type="primary">{{ list.length }}</van-tag></div>
            </template>
            <van-swipe-cell v-for="item in list" :key="item.uuid" right-width="60">
              <van-cell  :title="item.deviceName + '@' + item.phoneEnding" :label="'格口:' + item.doorIndex + ' 预计取餐时间：' + item.showTime" />
              <template #right>
                <div class="del" @click="onCollect(item)">取出</div>
              </template>
            </van-swipe-cell>
          </van-collapse-item>
          <van-collapse-item name="2">
            <template #title>
              <div>已完成<van-tag style="margin-left: 10px" plain type="primary">{{ finishedCount }}</van-tag></div>
            </template>
            <van-cell v-for="item in finishedList" :key="item.uuid" :title="item.deviceName + '@' + item.phoneEnding">
              <template #label>
                格口:{{ item.doorIndex + ' 取餐时间：' + item.showTime }}<br>
                <van-tag type="primary">存</van-tag>{{item.riderInfo ? item.riderInfo.username : ''}}
                <van-tag style="margin-left: 10px" type="success">取</van-tag>{{item.userInfo ? item.userInfo.username : ''}}
              </template>
            </van-cell>
          </van-collapse-item>
        </van-collapse>
      </van-list>
    </van-pull-refresh>

    <van-dialog v-model="showAdd" title="添加任务" :show-confirm-button="false" close-on-click-overlay>
      <van-form @submit="onSubmitRequest">
        <van-field readonly clickable @click="showCabinet = true" name="deviceId" v-model="cabinet.name" label="设备" :rules="[{required: true, message: '请填写'}]"/>

        <van-field readonly clickable @click="clickShowRider" name="riderName" v-model="rider" label="骑手" :rules="[{required: true, message: '请填写'}]" />

        <van-field readonly clickable name="amount" @click="showNumber = true" v-model="orderCount" label="订单数量" :rules="[{required: true, message: '请填写'}]"/>
        <div style="display: flex;justify-content: center;align-items: center">
          <van-button native-type="submit" round type="info" style="width: 200px;margin: 20px 0">提交</van-button>
        </div>
      </van-form>
    </van-dialog>
    <van-popup v-model="showCabinet" position="bottom">
      <van-picker
          show-toolbar
          :columns="cabinetList.map(it => `${it.name} (${it.area})`)"
          @confirm="onConfirmCabinet"
          @cancel="showCabinet = false"
      />
    </van-popup>
    <van-popup v-model="showRider" position="bottom">
      <van-picker
          :key="target"
          ref="riderPicker"
          show-toolbar
          :columns="riderPickerList"
          cancel-button-text="订单统计"
          @confirm="onConfirmRider"
          @cancel="onClickShowOrderInfo"
          @change="areaChanged"
      />
    </van-popup>
    <van-popup v-model="showNumber" position="bottom">
      <van-picker
          ref="numberPicker"
          :columns="numberList"
          show-toolbar
          @cancel="showNumber = false"
          @change="numberChanged"
          @confirm="confirmNumber"
      />
    </van-popup>

    <van-popup v-model="showRiderOrderCount" position="top">
<!--      <div style="font-size: 20px;margin: 10px 30px">{{ orderRider }}</div>-->
      <van-cell-group inset>
        <van-cell v-for="item in riderOrderInfoList" :key="item.device_name" :title="item.device_name" :value="item.amount" />
      </van-cell-group>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "requestList",
  data() {
    return {
      target: 1,
      showRiderOrderCount: false,
      riderOrderInfoList: [],
      orderRider: '',
      tmpRiderId: 0,
      tmpArea: '',
      showNumber: false,
      refreshing: false,
      loading: false,
      finished: false,
      list: [],
      finishedList: [],
      finishedCount: 0,
      showAdd: false,
      cabinet: {deviceId: '', name: ''},
      rider: '',
      riderId: 0,
      orderCount: '1',
      cabinetList: [],
      riderList: [],
      riderPickerList: [],
      areaList: [],
      showCabinet: false,
      showRider: false,
      activeGroup: '1',
      pickRiderActiveGroup: '',
      numberList: ['1', '2', '3', '4', '5', '6']
    }
  },
  watch: {
    showAdd(newVal) {
      if (newVal) {
        this.loadRiderCabinetData()
      }
    }
  },
  methods: {
    async changeGroup(event) {
      if (event === '2') {
        let res = await this.$http.get('mock/requestInfo?state=finished')
        if (res.data) {
          let finished = res.data.finished
          finished.forEach(it => {
            let date = new Date(it.startCollectTime)
            it.showTime = `${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes()}:${date.getSeconds() < 10 ? ('0' + date.getSeconds()) : date.getSeconds()}`
          })
          finished.sort((a, b) => {
            return b.startCollectTime - a.startCollectTime
          })
          this.finishedList = finished
        }
      }
    },
    onClickShowOrderInfo() {
      this.fetchRiderOrderInfo(this.tmpRiderId)
    },
    fetchRiderOrderInfo(riderId) {
      console.log(riderId)
      this.$http.get('mock/riderInfo/' + riderId).then(res => {
        if (res.data && res.data.length) {
          this.riderOrderInfoList = res.data
          this.showRiderOrderCount = true
        } else {
          this.$toast('没有数据')
        }
      })
    },
    clickShowRider() {
      if (this.cabinet.area) {
        this.tmpArea = this.cabinet.area
        let index = this.areaList.indexOf(this.cabinet.area)
        let pickerColumns = [{values: this.areaList, defaultIndex: index}]
        pickerColumns.push({values: this.filterRider(this.cabinet.area), defaultIndex: 0})
        this.riderPickerList = pickerColumns
        this.tmpRiderId = pickerColumns[1].values[0].riderId
      }

      this.showRider = true
      this.target++
      // this.$refs.riderPicker.setColumnIndex(1, 0)
    },
    filterRider(area) {
      return this.riderList.filter(it => it.area === area).map(rider => { return {text: `${rider.username}[${rider.todayOrderCount}]`, riderId: rider.id}})
    },
    areaChanged(picker, values) {
      console.log('areaChanged', values, 'tmpArea', this.tmpArea)
      if (values[0] !== this.tmpArea) {
        this.tmpArea = values[0]
        this.riderPickerList[1].values = this.filterRider(values[0])
        this.tmpRiderId = this.riderPickerList[1].values[0].riderId
      } else {
        this.tmpRiderId = values[1].riderId
      }
    },

    numberChanged(picker, values) {
      console.log(picker, values)
      this.showNumber = false
      this.orderCount = values
    },
    onSubmitRequest(values) {
      console.log(values)
      this.$toast.loading()
      values.deviceId = this.cabinet.deviceId
      values.riderId = this.riderId
      this.$http.post('mock/mockKeep', values).then(res => {
        this.$toast.clear(true)
        this.showAdd = false
        if (res.data === 'good') {
          this.$toast.success('提交成功')
          this.onLoad()
        } else {
          this.$toast.fail(res.data.msg)
        }
      })
    },
    onConfirmCabinet(value) {
      value = value.split(' ')[0]
      this.showCabinet = false
      for (let cabinet of this.cabinetList) {
        if (cabinet.name === value) {
          this.cabinet = cabinet
          break
        }
      }
    },
    onConfirmRider(value, index) {
      console.log(value, index)
      this.showRider = false
      this.rider = value[1].text.split('[')[0]
      this.riderId = value[1].riderId
    },

    confirmNumber() {
      this.showNumber = false
    },

    loadRiderCabinetData() {
      this.$http.get('mock/cabinet').then(res => {
        this.cabinetList = res.data
      })
      this.$http.get('mock/riderInfo').then(res => {
        /*let groupMap = new Map()
        res.data.forEach(rider => {
          if (groupMap.has(rider.area)) {
            groupMap.get(rider.area).push({text: `${rider.username}[${rider.todayOrderCount}]`, riderId: rider.id})
          } else {
            groupMap.set(rider.area, [{text: `${rider.username}[${rider.todayOrderCount}]`, riderId: rider.id}])
          }
        })
        let list = []
        groupMap.forEach(((value, key) => {
          list.push({text: key, children: value})
        }))*/
        res.data.forEach(it => {
          if (!this.areaList.includes(it.area)) {
            this.areaList.push(it.area)
          }
        })
        console.log(this.areaList)
        this.riderList = res.data
      })
    },

    onSubmit(values) {
      console.log(values)
      this.$toast.loading()
      this.$http.post('mock/userInfo', values).then(res => {
        this.$toast.clear(true)
        this.showAdd = false
        if (res.data === 'good') {
          this.onLoad()
        }
      })
    },
    onRefresh() {
      console.log('refreshing')
      this.onLoad()
    },
    onLoad() {
      console.log('onLoad')
      this.loading = true
      this.$http.get('mock/requestInfo').then(res => {
        this.loading = false

        let pending = res.data.pending
        pending.forEach(it => {
          let date = new Date(it.startCollectTime)
          it.showTime = `${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes()}:${date.getSeconds() < 10 ? ('0' + date.getSeconds()) : date.getSeconds()}`
        })
        this.list = pending
        this.finishedCount = res.data.finishedCount

        /*let finished = res.data.finished
        finished.forEach(it => {
          let date = new Date(it.startCollectTime)
          it.showTime = `${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes()}:${date.getSeconds() < 10 ? ('0' + date.getSeconds()) : date.getSeconds()}`
        })
        finished.sort((a, b) => {
          return b.startCollectTime - a.startCollectTime
        })
        this.finishedList = finished*/

        this.finished = true
        this.refreshing = false
        this.$bus.$emit('data-refresh', {type:'task', amount:this.list.length})
      }).catch(err => {
        console.log(err)
        this.loading = false
        this.finished = true
        this.refreshing = false
      })
    },
    onCollect(item) {
      this.$dialog({title:'确定现在取出？', showCancelButton: true}).then(() => {
        this.$toast.loading({mask: true})
        this.$http.post('mock/requestInfo/collect', {uuid: item.uuid}).then(res => {
          this.$toast.clear(true)
          if (res.data === 'good') {
            this.onLoad()
          }
        })
      }).catch(() => {
        console.log('cancel')
      })
    }
  }
}
</script>

<style scoped>
/deep/ .van-list__finished-text {
  margin-bottom: 100px;
}

.del {
  background-color: red;
  height: 100%;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.fab {
  width: 46px;
  height: 46px;
  border-radius: 23px;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 16px;
  bottom: 66px;
  z-index: 999;
}
.fab:active {
  background-color: brown;
}
</style>
